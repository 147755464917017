<template>
  <div class="row">
    <div class="col-12 col-lg-6 c">
      <div class="card">
        <div class="card-header">
          <h4><i class="fa fa-plus"></i> إضافة مكان</h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label for="">الإسم</label>
            <input type="text" class="form-control" v-model="title" />
          </div>
          <div class="form-group">
            <label for="">رسوم التوصيل</label>
            <input
              type="number"
              class="form-control"
              v-model="delivery_price"
            />
          </div>
          <b-form-checkbox
            checked="true"
            name="check-button"
            switch
            inline
            :value="true"
            v-model="install"
          >
            مُتاح التركيب
          </b-form-checkbox>
          <div class="form-group g" v-if="install">
            <label for="">رسوم التركيب</label>
            <input type="number" class="form-control" v-model="install_price" />
          </div>
          <div class="col-12 text-center g">
            <button class="btn btn-success" @click="add()">
              إضافة المكان <i class="fa fa-arrow-left"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BFormCheckbox,
    ToastificationContent,
  },
  data() {
    return {
      title: null,
      install: false,
      delivery_price: 0,
      install_price: 0,
    };
  },
  methods: {
    add() {
      var g = this;
      if (g.title) {
        $.post(api + "/console/places/add", {
          jwt: user.jwt,
          title: g.title,
          install: g.install,
          delivery_price: g.delivery_price,
          install_price: g.install_price,
        }).then(function () {
          g.title = null;
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "تم بنجاح",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        });
      }
    },
  },
};
</script>

<style>
</style>